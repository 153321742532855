<template>
  <div v-on-clickaway="closeMenu" class="assist">
    <slot name="trigger">
      <b-tooltip
        class="assist-trigger"
        label="AI Assist"
        position="is-top"
        type="is-dark"
      >
        <p class="assist-trigger-wrapper" @click="toggleMenu()">
          <img
            :src="
              require(`@/assets/icons/lightning${
                references.length > 0 ? '-filled' : ''
              }.svg`)
            "
            class="assist-trigger-icon"
            alt=""
          />
          <span v-if="references.length > 0" class="assist-trigger-count">
            {{ references.length }}
          </span>
        </p>
      </b-tooltip>
    </slot>
    <transition name="fade">
      <div v-show="menuOpen" class="assist-menu-wrapper">
        <div class="assist-menu">
          <div
            class="assist-menu-item"
            :class="{ disabled: references.length === 0 }"
            @click="openReferences()"
          >
            <p class="assist-menu-item-info">
              <img
                src="@c/assets/icons/appearance.svg"
                alt=""
                class="assist-menu-item-icon"
              />
              <span class="assist-menu-item-text">Appears in</span>
            </p>
            <span class="assist-menu-item-count">
              {{ references.length }}
            </span>
          </div>
          <div class="assist-menu-item" @click="openSimilarSlides()">
            <p class="assist-menu-item-info">
              <img
                src="@c/assets/icons/similar.svg"
                alt=""
                class="assist-menu-item-icon"
              />
              <span class="assist-menu-item-text">Similar slides</span>
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'SubresourceAssistMenu',
  mixins: [clickaway],
  props: {
    references: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    menuOpen: false
  }),
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    },
    closeMenu() {
      this.menuOpen = false
    },
    openSimilarSlides() {
      this.closeMenu()
      this.$emit('openSimilar')
    },
    openReferences() {
      this.closeMenu()
      this.$emit('openReferences')
    }
  }
}
</script>

<style scoped lang="scss">
.assist {
  position: relative;
  &-trigger {
    &-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0.25rem 0.5rem;
      border-radius: 999rem;
      background: white;
      border: 1px solid rgba(#000, 8%);
    }

    &-icon {
      height: 1.25rem;
    }

    &-count {
      color: $primary;
      font-weight: 700;
    }
  }

  &-menu {
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    min-width: 20rem;
    padding: 0.5rem 0;

    &-wrapper {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 99;
      padding-top: 0.5rem;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      padding: 0.5rem 1.5rem;
      cursor: pointer;

      &:hover {
        background: rgba(#000, 6%);
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.7;
      }

      &-info {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      &-icon {
        height: 1.25rem;
      }

      &-text {
      }

      &-count {
        justify-self: flex-end;
        padding: 0.1rem 0.5rem;
        color: $primary;
        font-weight: 700;
        background: #ebf1ff;
        border-radius: 999rem;
      }
    }
  }
}
</style>
