var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "slide-visualisation",
      on: {
        mouseenter: _vm.onMouseEnter,
        mouseleave: _vm.onMouseLeave,
        click: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.openSlideDetail()
        }
      }
    },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isHovering || _vm.slideCollected,
                expression: "isHovering || slideCollected"
              }
            ],
            staticClass: "slide-visualisation-buttons"
          },
          [
            _c("CollectSlideButton", {
              attrs: {
                subresource: _vm.subresource,
                rank: _vm.rank,
                "search-id": _vm.searchId
              }
            }),
            _c(
              "transition",
              { attrs: { name: "fade" } },
              [
                _c(
                  "b-tooltip",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isHovering,
                        expression: "isHovering"
                      }
                    ],
                    attrs: {
                      label: _vm.isBookmarked
                        ? "Remove bookmark"
                        : "Add bookmark",
                      position: "is-top",
                      type: "is-dark",
                      "append-to-body": ""
                    }
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-bookmark",
                        attrs: { rounded: "", size: "is-small" },
                        on: { click: _vm.toggleBookmark }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@c/assets/icons/bookmark" +
                              (_vm.isBookmarked ? "-filled" : "") +
                              ".svg")
                          }
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "transition",
              { attrs: { name: "fade" } },
              [
                _c("SubresourceAssistMenu", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isHovering,
                      expression: "isHovering"
                    }
                  ],
                  attrs: { references: _vm.subresource.references },
                  on: {
                    openSimilar: function($event) {
                      return _vm.openSlideDetail("similar")
                    },
                    openReferences: function($event) {
                      return _vm.openSlideDetail("references")
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          class: {
            "preview-image-container": true,
            "preview-border-normal": true,
            "zoom-cursor": _vm.withModal
          },
          on: {
            click: function($event) {
              return _vm.openSlideDetail()
            }
          }
        },
        [
          _c("div", { staticClass: "slide-hover-scrim" }),
          _c("img", {
            ref: "image",
            staticClass: "preview-image",
            attrs: { src: _vm.subresource.preview },
            on: {
              load: function($event) {
                return _vm.$emit("load")
              },
              error: function($event) {
                return _vm.$emit("cantLoad")
              }
            }
          }),
          _c("QualityLabelGroup", {
            staticClass: "slide-labels",
            attrs: { labels: _vm.subresource.labels, bordered: true }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }