<template>
  <div
    class="slide-visualisation"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @click.self="openSlideDetail()"
  >
    <transition name="fade">
      <div
        v-show="isHovering || slideCollected"
        class="slide-visualisation-buttons"
      >
        <CollectSlideButton
          :subresource="subresource"
          :rank="rank"
          :search-id="searchId"
        />
        <transition name="fade">
          <b-tooltip
            v-show="isHovering"
            :label="isBookmarked ? 'Remove bookmark' : 'Add bookmark'"
            position="is-top"
            type="is-dark"
            append-to-body
          >
            <b-button
              class="btn-bookmark"
              rounded
              size="is-small"
              @click="toggleBookmark"
            >
              <img
                :src="
                  require(`@c/assets/icons/bookmark${
                    isBookmarked ? '-filled' : ''
                  }.svg`)
                "
              />
            </b-button>
          </b-tooltip>
        </transition>
        <transition name="fade">
          <SubresourceAssistMenu
            v-show="isHovering"
            :references="subresource.references"
            @openSimilar="openSlideDetail('similar')"
            @openReferences="openSlideDetail('references')"
          />
        </transition>
      </div>
    </transition>
    <div
      :class="{
        'preview-image-container': true,
        'preview-border-normal': true,
        'zoom-cursor': withModal
      }"
      @click="openSlideDetail()"
    >
      <div class="slide-hover-scrim"></div>
      <!-- Preview image -->
      <img
        ref="image"
        :src="subresource.preview"
        class="preview-image"
        @load="$emit('load')"
        @error="$emit('cantLoad')"
      />

      <QualityLabelGroup
        :labels="subresource.labels"
        class="slide-labels"
        :bordered="true"
      />
    </div>
  </div>
</template>

<script>
import {
  addBookmarkToSubresource,
  deleteBookmarkFromSubresource
} from '@/services/bookmarkService'
import {
  eventContext,
  sendFileClickThroughEvent,
  sendInspectEvent
} from '@/services/feedbackService'
import { mapActions, mapGetters } from 'vuex'
import CollectSlideButton from './subcomponents/CollectSlideButton.vue'
import QualityLabelGroup from './subcomponents/QualityLabelGroup.vue'
import SubresourceAssistMenu from './subcomponents/SubresourceAssistMenu.vue'

export default {
  name: 'SlideVisualisation',
  components: {
    SubresourceAssistMenu,
    CollectSlideButton,
    QualityLabelGroup
  },
  props: {
    subresource: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    loadedResources: {
      type: Number,
      required: true
    },
    totalResources: {
      type: Number,
      required: true
    },
    withModal: {
      type: Boolean,
      default: true
    },
    rank: {
      type: Number,
      required: true
    },
    searchId: {
      type: String,
      default: ''
    },
    location: {
      type: String,
      default: 'search results'
    }
  },
  data() {
    return {
      isHovering: false,
      showDetail: false,
      scrollingTimer: undefined,
      isBookmarked: false
    }
  },
  computed: {
    ...mapGetters(['selectedSlides', 'lastSearchId']),
    slideCollected() {
      return this.selectedSlides
        .map(ss => ss.trace_id || ss.subresource_trace_id)
        .includes(this.subresource.trace_id)
    }
  },
  watch: {
    showDetail() {
      if (this.showDetail) {
        document.addEventListener('keyup', this.handleKeyUp)
        this.$root.$emit('opened-detail')
      }
      if (!this.showDetail)
        document.removeEventListener('keyup', this.handleKeyUp)
    }
  },
  mounted() {
    this.isBookmarked = !!this.subresource.bookmark
    this.$root.$on('open-detail', this.openDetail)
    this.$root.$on('close-slide-detail', () => (this.showDetail = false))
  },
  destroyed() {
    clearTimeout(this.scrollingTimer)
  },
  methods: {
    ...mapActions(['setResourceModal']),
    handleKeyUp(event) {
      if (this.showDetail && (event.keyCode === 37 || event.keyCode === 39)) {
        if (this.index === 0 && event.keyCode === 37) return
        if (this.index === this.totalResources - 1 && event.keyCode === 39)
          return
        this.showDetail = false
        const index = event.keyCode === 37 ? this.index - 1 : this.index + 1
        this.$root.$emit('open-detail', index)
      }
    },
    openDetail(index) {
      if (this.withModal) {
        if (this.index !== index) return
        this.showDetail = true
      }
    },
    async toggleBookmark() {
      try {
        if (this.isBookmarked) {
          await deleteBookmarkFromSubresource(
            this.$route.params.workspace_id,
            this.subresource.uuid,
            this.$route.query.act_as
          )
          this.isBookmarked = false
        } else {
          await addBookmarkToSubresource(
            this.$route.params.workspace_id,
            this.subresource.uuid,
            this.$route.query.act_as
          )
          this.isBookmarked = true
        }
      } catch (e) {
        this.$toast.error(e, `${this.isBookmarked ? 'removing' : 'adding'}`)
      }
    },
    clamp(min, max, value) {
      if (value < min) return min
      if (value > max) return max
      return value
    },
    onMouseEnter() {
      this.isHovering = true
    },
    onMouseLeave() {
      this.isHovering = false
    },
    async openSlideDetail(scroll = '') {
      this.modalScroll = scroll
      if (this.withModal) {
        this.isHovering = false
        this.setResourceModal({
          resource_id: this.subresource.uuid,
          resource_level: 'subresource',
          resource_modal_props: {
            bookmark: this.subresource.bookmark,
            scroll: this.modalScroll,
            rank: this.rank
          },
          buefy_modal_props: {}
        })
        sendInspectEvent(this.$route.params.workspace_id, {
          subresource_trace_id: this.subresource.trace_id,
          resource_trace_id: this.subresource.references[0].resource.trace_id,
          tab: this.$route.params.tab || 'all',
          context: eventContext.subresource,
          rank: this.rank,
          comment: `from ${this.location}`,
          ...(this.searchId ? { search_id: this.searchId } : {})
        })
      }
    },
    async onResourceRedirect() {
      sendFileClickThroughEvent(this.$route.params.workspace_id, {
        subresource_trace_id: this.subresource.trace_id,
        resource_trace_id: this.subresource.references[0].resource.trace_id,
        tab: this.$route.params.tab || 'all',
        context: eventContext.subresource,
        comment: `from slide info hover in ${this.location}`,
        rank: this.rank,
        ...(this.searchId ? { search_id: this.searchId } : {})
      })
    },
    hideDetail() {
      this.showDetail = false
    },
    handleScrolling() {
      this.scrolling = true
      this.scrollingTimer = setTimeout(() => {
        this.scrolling = false
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-labels {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
}

.card-wrapper {
  position: relative;
}
.slide-visualisation {
  position: relative;
  &:hover {
    .slide-hover-scrim {
      opacity: 0.15;
    }
  }

  &-buttons {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    z-index: 999;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: white;
    transition: all ease 200ms;
  }

  .preview-image {
    height: 100%;
    display: block;
    border-radius: 4px;
  }
  .slide-hover-scrim {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.0127407) 6.47%,
      rgba(0, 0, 0, 0.0485926) 12.46%,
      rgba(0, 0, 0, 0.104) 18.04%,
      rgba(0, 0, 0, 0.175407) 23.29%,
      rgba(0, 0, 0, 0.259259) 28.27%,
      rgba(0, 0, 0, 0.352) 33.08%,
      rgba(0, 0, 0, 0.450074) 37.77%,
      rgba(0, 0, 0, 0.549926) 42.42%,
      rgba(0, 0, 0, 0.648) 47.11%,
      rgba(0, 0, 0, 0.740741) 51.91%,
      rgba(0, 0, 0, 0.824593) 56.9%,
      rgba(0, 0, 0, 0.896) 62.15%,
      rgba(0, 0, 0, 0.951407) 67.73%,
      rgba(0, 0, 0, 0.987259) 73.72%,
      #000000 80.19%
    );
    transform: rotate(180deg);
    opacity: 0;
    transition: opacity 0.4s ease;
    height: 100%;
    width: 100%;
  }
  .preview-image-container {
    position: relative;
    height: 100%;
    border-radius: 3px;
    overflow: visible;

    &:hover .subresource-hover-scrim {
      opacity: 0.16;
    }
  }

  .preview-border-normal {
    border: 0.5px solid rgba(0, 0, 0, 0.08);
  }

  .zoom-cursor {
    cursor: zoom-in;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.btn-bookmark {
  aspect-ratio: 1 / 1;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &::v-deep span {
    line-height: 1;
  }
}
</style>
